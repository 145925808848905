<template>
  <div class="zip-code-modal">
    <h3>{{ $t('zipCodeModal.title') }}</h3>
    <p class="zip-code-modal-description">{{ $t('zipCodeModal.description') }}</p>
    <InputField
      v-model="locality"
      type="text"
      :placeholder-value="$t('search.locationPlaceholder')"
      class-variant="full-width u-m-b-less"
      :clearable="true"
      :input-rules="['numeric']"
      @clear="clear()"
      @validate="checkFormInvalid"
    >
      <template #icon>
        <div class="icon-location u-bg-dark-blue" />
      </template>
    </InputField>
    <div v-if="error" class="error">{{ error }}</div>
    <p class="zip-code-modal-legal" v-html="$t('zipCodeModal.legal')" />

    <div v-if="locality" class="zip-code-modal-buttons">
      <MainButton :label="$t('actions.save')" @click="findLocality" />
      <MainButton :label="$t('actions.clear')" class-variant="btn-grey" @click="clear" />
    </div>
  </div>
</template>

<script setup>
import InputField from '@/components/InputField/InputField'
import MainButton from '@/components/Buttons/MainButton/MainButton'

import postalCodes from 'postal-codes-js'
import { useUserStore } from '@/stores/user'
import { useFiltersStore } from '@/stores/filters'

const { $api } = useNuxtApp()

const userStore = useUserStore()
const filtersStore = useFiltersStore()
const error = ref(null)

const locality = ref(userStore.getZipCode || '')
const coords = computed(() => userStore.getLocation)

const localityFilter = computed(() => {
  return (
    filtersStore.filterByType('LOCALITY')?.[0] || {
      label: locality.value,
      value: `${locality.value}+${coords.value?.lat},${coords.value?.lng}`,
      query: 'locality',
      type: 'LOCALITY',
      display: false,
    }
  )
})

const { request } = useApiRequest()

const clear = () => {
  locality.value = null
  userStore.setZipCode(null)
  userStore.setLocation({ lat: null, lng: null })
  filtersStore.removeFilterTypes({ types: ['LOCALITY'], shouldListen: true })
}

const checkFormInvalid = () => {
  const { $i18n } = useNuxtApp()
  const { tld } = useCurrentLocale()
  if (locality.value && postalCodes.validate(tld, locality.value) !== true) {
    error.value = $i18n.t('subscribe.errors.zip_code')
    return
  } else {
    error.value = null
  }
}
const findLocality = async () => {
  checkFormInvalid()
  if (error.value) {
    return
  }
  if (locality.value?.length) {
    error.value = null
    let resultCoords = null
    if (!userStore.getLocation?.lat || locality.value !== userStore.getZipCode) {
      resultCoords = await request({
        req: $api().location.findLocation(locality.value),
        loader: false,
      })
    } else {
      resultCoords = userStore.getLocation
    }
    userStore.setZipCode(locality.value)
    userStore.setLocation(resultCoords)
    filtersStore.addFiltersUnique([localityFilter.value])
    close()
  }
}

const emit = defineEmits(['close'])

const close = () => {
  emit('close')
}
</script>

<style lang="scss" scoped>
.zip-code-modal {
  padding: $spacing-md;
  h3 {
    font-size: pxToRem(20px);
    margin-bottom: $spacing-md;
    margin-top: $spacing-xl;
  }
  &-description {
    font-size: pxToRem(15px);
    margin-bottom: $spacing-md;
  }
  &-legal {
    color: var(--steel-grey);
    font-size: pxToRem(12px);
    font-weight: 400;
    margin-top: $spacing-md;
    margin-bottom: $spacing-md;
  }
  &-buttons {
    display: flex;
    gap: $spacing-md;
    flex-direction: column;
    margin-top: $spacing-md;
  }
}
</style>
